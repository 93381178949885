<template>
    <div class="create-users-container" :class="[$mq]">
        <div class="question">
            {{ $t('question.create_users') }}
        </div>
        <div class="description">
            {{ $t('question.description') }}
        </div>
        <div class="andy-cool">
            <img class="andy" src="~@/../public/img/andy_pasos1.svg" alt="andy-cool" />
        </div>
        <div class="btn-create" @click="goToCreateUser()">{{ $t('question.create_users_btn') }}</div>
        <div class="btn-cancel" @click="cancel()">{{ $t('question.create_users_btn_cancel') }}</div>
    </div>
</template>

<script>
export default {
    name: 'CreateUsersQuestion',
    methods: {
        goToCreateUser() {
            this.$emit('goToCreateUser')
        },
        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style lang="scss" scoped>
.create-users-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 15px;

    .question {
        @include font-size(l);
        font-family: $text-bold;
        color: $main-dark;
        line-height: 45px;
        text-align: center;
    }
    .description {
        @include font-size(m);
        font-family: $text;
        color: $inactive-s80;
        text-align: center;
    }
    .btn-create {
        @include font-size(sm);
        @include border-radius(4px);
        font-family: $text-bold;
        color: #fff;
        background-color: $main-dark;
        padding: 20px 40px;
        margin-top: 20px;
        cursor: pointer;
    }
    .btn-cancel {
        @include font-size(sm);
        text-decoration: underline;
        color: $main;
        font-family: $text;
        padding-top: 20px;
        cursor: pointer;
    }
    &.landscape {
        gap: 30px;
        .question {
            @include font-size(38px);
        }
        .description {
            @include font-size(28px);
        }
        .btn-create {
            @include font-size(25px);
        }
        .btn-cancel {
            @include font-size(20px);
        }
    }
    &.desktop {
        gap: 30px;
        .question {
            @include font-size(38px);
        }
        .description {
            @include font-size(28px);
        }
        .btn-create {
            @include font-size(25px);
        }
        .btn-cancel {
            @include font-size(20px);
        }
    }
    &.portrait {
        padding: 15px;
    }
}
</style>
