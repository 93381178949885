<template>
    <div id="nps-container" :class="$mq">
        <div class="nps-content" :class="$mq">
            <div class="close-nps" @click="close('ignored')"></div>
            <div class="nps-header" :class="{ sended: numberSended && textSended }">
                <div class="nps-header-title" v-if="!textSended">
                    {{ $t('nps.title') }}
                </div>
                <div class="nps-header-image">
                    <img class="andy-logo" src="@/../public/img/alexhead_06.svg" />
                </div>
            </div>
            <transition class="numpad-transition" name="slide-fade" mode="out-in">
                <div class="nps-body" v-if="!numberSended" key="0">
                    <div class="nps-body-content-text" v-html="$t('nps.msg')"></div>
                    <div class="nps-body-content-buttons">
                        <div class="nps-body-content-button" v-for="button in 10" @click="number(button)" :class="{ selected: button == valueSelected }" :key="button">{{ button }}</div>
                    </div>
                    <div class="nps-body-content-description">
                        <div class="text">{{ $t('nps.not_probably') }}</div>
                        <div class="text">{{ $t('nps.probably') }}</div>
                    </div>
                </div>
                <div class="nps-body" v-else-if="!textSended" key="1">
                    <div class="nps-body-content-text" v-html="$t('nps.msg2')"></div>
                    <textarea ref="textAreaComment" v-model.lazy="comment" class="note"></textarea>
                </div>
                <div class="nps-body" v-else key="2">
                    <div class="nps-thanks">
                        {{ $t('nps.thanks') }}
                    </div>
                    <div class="working">
                        {{ $t('nps.working') }}
                    </div>
                </div>
            </transition>
            <div class="nps-footer" v-if="!textSended">
                <div class="btn-submit" @click="send()">{{ $t('nps.send') }}</div>
            </div>
            <div class="nps-footer" :class="{ return: textSended }" v-else>
                <div class="btn-submit" @click="close(false)">{{ $t('nps.back') }}</div>
            </div>
            <div class="no-reply" v-if="!numberSended" @click="close('discarded')">{{ $t('nps.no_reply') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Nps',
    props: {
        employee_id: {
            type: String,
            default: false
        }
    },
    data() {
        return {
            valueSelected: false,
            numberSended: false,
            textSended: false,
            comment: ''
        }
    },
    methods: {
        number(num) {
            this.valueSelected = num
        },
        send() {
            var self = this
            if (this.valueSelected) {
                if (fnCheckConnection()) {
                    if (!this.numberSended) {
                        this.numberSended = true
                        this.$store.dispatch('employee/responseNPS', { employee_id: this.employee_id, score: this.valueSelected })
                        setTimeout(() => {
                            self.$refs.textAreaComment.focus()
                        }, 300)
                    } else {
                        if (this.comment != '') {
                            this.$store.dispatch('employee/responseNPS', { employee_id: this.employee_id, score: this.valueSelected, text: this.comment })
                        }
                        this.textSended = true
                    }
                } else {
                    this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('nps.offline') })
                }
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('nps.no_value') })
            }
        },
        close(param) {
            var self = this
            if (param == 'discarded') {
                self.$store.dispatch('employee/responseNPS', { employee_id: self.employee_id, discarded: true }).then(() => {
                    self.$emit('close')
                })
            }
            if (param == 'ignored' && !self.numberSended) {
                self.$store.dispatch('employee/responseNPS', { employee_id: self.employee_id, ignored: true }).then(() => {
                    self.$emit('close')
                })
            } else {
                this.$emit('close')
            }
        }
    }
}
</script>

<style lang="scss">
#nps-container {
    @include display-flex();
    width: 100%;
    height: 100%;
    .nps-content {
        // transitions
        .slide-fade-enter-active {
            transform: translateX(40px);
        }

        .slide-fade-leave-active {
            transition: all 0.2s;
        }

        .slide-fade-enter-from,
        .slide-fade-leave-to {
            transform: translateX(-40px);
            opacity: 0;
        }
        // end transitions

        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-evenly);
        width: 100%;
        height: 100%;
        background-color: $default-bg-color;
        padding: 20px;
        max-width: 600px;
        margin: 0 auto;
        overflow-y: auto;

        .close-nps {
            @include background($image: img('close_dark.svg'), $size: 20px);
            @extend .interaction;
            top: 16px;
            right: 16px;
            position: absolute;
            width: 20px;
            height: 20px;
        }

        .nps-header {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
            width: 100%;
            height: 30%;
            .nps-header-title {
                @include font-size(m);
                color: $main;
                font-family: $text-bold;
                height: 40px;
            }

            .nps-header-image {
                width: 110px;
                // height: 110px;
                min-height: 80px;
                max-height: 110px;

                .andy-logo {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &.sended {
                height: 0px;
            }
        }
        .nps-body {
            width: 100%;

            .nps-body-content-text {
                color: $neutro-s80;
                font-family: $text;
                text-align: center;

                b {
                    color: $neutro-s80;
                    font-family: $text-sbold;
                }
                padding-bottom: 20px;
            }
            .nps-body-content-buttons {
                @include display-flex();
                @include justify-content(space-between);
                gap: 5px;
                .nps-body-content-button {
                    @extend .interaction;
                    @include display-flex();
                    @include align-items(center);
                    @include justify-content(center);
                    @include border-radius(5px);
                    width: clamp(23px, 40px, 45px);
                    height: 45px;
                    font-family: $text-light;
                    background-color: #fff;

                    &.selected {
                        background-color: $main;
                        font-family: $text-sbold;
                        color: #fff;
                    }
                }
            }
            .nps-body-content-description {
                @include display-flex();
                @include justify-content(space-between);
                padding-top: 10px;

                .text {
                    @include font-size(xs);
                    color: $neutro-s80;
                    font-family: $text;
                }
            }
            .note {
                width: 100%;
                height: 120px;

                &:focus {
                }
            }
            .nps-thanks {
                text-align: center;
                width: 100%;
                line-height: 45px;
                @include font-size(xl);
                font-family: $text-bold;
                padding-bottom: 40px;
            }
            .working {
                text-align: center;
                width: 100%;
                line-height: 30px;
                @include font-size(m);
                font-family: $text;
            }
        }
        .nps-footer {
            @include display-flex();
            @include justify-content();
            width: 100%;
            height: 60px;
            .btn-submit {
                @include border-radius(4px);
                @include font-size(ml);
                font-family: $text-bold;
                padding: 16px 70px;
                background-color: $main;
                color: #fff;
                @include align-self(center);
                @extend .interaction;
            }
            &.return {
                position: relative;
                top: -65px;
            }
        }
        .no-reply {
            @extend .interaction;
            @include font-size(s);
            color: $neutro-s80;
            font-family: $text-light;
            text-align: center;
            text-decoration: underline;
            position: absolute;
            bottom: 15px;
        }
        &.desktop {
            @include align-self(center);
            max-height: 900px;
            position: relative;
        }
    }
}
</style>

// Portrait syles
<style lang="scss">
#nps-container.portrait {
    .nps-content {
        padding: 10px;
    }
}
</style>
