<template>
    <div id="alex-app" :class="[{ 'opened-menu': !['portrait'].includes($mq) && openedMenu }, { offline: !connState }, $mq]">
        <template v-if="showErrorDeviceConfig">
            <ErrorDeviceConfig></ErrorDeviceConfig>
        </template>
        <template v-else-if="showTrialEndScreen">
            <TrialEndScreen></TrialEndScreen>
        </template>
        <template v-else-if="(update_app_min || update_available) && showUpdateScreen">
            <UpdateApp :min="update_app_min" @updateLater="hidePopupUpdate"></UpdateApp>
        </template>
        <!-- If user logged -->
        <template v-else-if="employeeLogged && userHasValidConditionsVersion">
            <!-- showCreateUsers -->
            <template v-if="showPopupType == 'createUsers'">
                <CreateUsersQuestion @goToCreateUser="goToCreateUser" @cancel="showNextDayPopupAskCreateNewUsers"></CreateUsersQuestion>
            </template>
            <!-- !npsClosed -->
            <template v-else-if="showPopupType == 'nps'">
                <Nps @close="closeNPS()" :employee_id="employeeLogged.id"></Nps>
            </template>

            <!-- if doesnt show nps or create user form, continue with app normally -->
            <template v-else-if="!showPopupType">
                <div id="fullcontent" ref="fullcontent" v-if="pullRefresh" :class="{ pulling: pull.pulling, refresh: pull.refresh, overlay: ['portrait'].includes($mq) && (showingUser || openedMenu || openedSubMenu), 'hide-scrollbar': !['portrait'].includes($mq) }" @touchstart="handleHold" @touchmove="handleMove" @touchend="handleEnd" @mousedown="handleHold" @mousemove="handleMove" @mouseup="handleEnd">
                    <!-- <div class="pull-loader box" v-if="true || pull.refresh">
        <div class = "plCircle box">
          <div class = "plInner box">
            <div class = "plCore box"></div>
          </div>
        </div>
        </div>-->

                    <router-view v-if="loaded" />
                </div>
                <div id="fullcontent" ref="fullcontent" :class="[{ 'hide-scrollbar': !['portrait'].includes($mq) }, { overlay: ['portrait'].includes($mq) && (showingUser || openedMenu || openedSubMenu) }]" v-else>
                    <router-view v-if="loaded" />
                </div>
                <template v-if="['portrait'].includes($mq)">
                    <MenuTopPortrait v-show="showingTop" v-if="loaded"></MenuTopPortrait>
                    <transition name="leftToRight">
                        <ToolsBoxPortrait v-if="openedMenu && loaded"></ToolsBoxPortrait>
                    </transition>
                    <transition name="upToDown">
                        <SubMenuToolsPortrait v-if="openedSubMenu && loaded"></SubMenuToolsPortrait>
                    </transition>
                    <transition name="rightToLeft">
                        <UserBoxPortrait v-if="showingUser && loaded"></UserBoxPortrait>
                    </transition>
                </template>
                <template v-if="['landscape', 'desktop'].includes($mq)">
                    <MenuTop v-show="showingTop" v-if="loaded"></MenuTop>
                    <MenuTools v-show="showingTools" v-if="loaded"></MenuTools>
                </template>
                <div class="pusherMessage" v-if="pusherMessage">
                    {{ popupPusher() }}
                </div>
            </template>
        </template>

        <!-- If user is not logged -->
        <template v-else-if="loaded">
            <!-- Wizard if there is no user with role level 3 -->
            <FirstUserSiteWizard v-if="!hasLevel3Users" @finish="hasUsersLevelThree"></FirstUserSiteWizard>
            <!-- Login user -->
            <connect v-else></connect>
        </template>
    </div>
</template>

<script>
import MenuTop from '@/components/layout/MenuTop'
import MenuTopPortrait from '@/components/layout/MenuTopPortrait'
import MenuTools from '@/components/layout/MenuTools'
import ToolsBoxPortrait from '@/components/layout/ToolsBoxPortrait'
import SubMenuToolsPortrait from '@/components/layout/SubMenuToolsPortrait'
import UserBoxPortrait from '@/components/layout/UserBoxPortrait'
import UpdateApp from '@/components/layout/UpdateApp'
import ErrorDeviceConfig from '@/components/layout/ErrorDeviceConfig'
import Nps from '@/components/layout/Nps'
import CreateUsersQuestion from '@/components/layout/CreateUsersQuestion'
import FirstUserSiteWizard from '@/components/layout/FirstUserSiteWizard'
import connect from '@/components/domain/login/connect'
import TrialEndScreen from '@/components/layout/TrialEndScreen'
import { uuid } from 'vue-uuid'
export default {
    components: { MenuTop, MenuTopPortrait, MenuTools, ToolsBoxPortrait, SubMenuToolsPortrait, UserBoxPortrait, CreateUsersQuestion, UpdateApp, connect, Nps, FirstUserSiteWizard, TrialEndScreen, ErrorDeviceConfig },
    data() {
        return {
            loaded: false,
            connState: true,
            pull: {
                // -- States
                holding: false,
                pulling: false,
                refresh: false,

                // -- Params
                minPull: 20, // Min px to trigger pull
                minDiff: 300, // Min diff between the start and end of the swipe to trigger
                pClass: false, // Can trigger if has a class. Default: false. Example: 'pull-me-under'
                maxDistance: 100, // Max content distance traslate

                // -- Helpers
                yStart: false,
                yEnd: false,
                getLast: false,
                yLast: false,

                // Holding timeout
                tHold: 300, // Time on ms to trigger hold
                triggerHold: false, // Where the timeout will be
                tRefresh: 1500 // Time on ms to stand refreshing (HAY QUE CAMBIARLO TAMBIEN EN EL TIEMPO DE ANIMACION DEL CSS),

                // PWA
            },
            deferredPrompt: false,
            userHasValidConditionsVersion: false, // boolean to validate if user has accepted legal conditions
            npsClosed: false,
            createUsersQuestion: false,
            hasLevel3Users: true,
            key: 1,
            showUpdateScreen: true
        }
    },
    computed: {
        showPopupType() {
            if (this.key && this.employeeLogged) {
                if (this.employeeLogged.show_nps && this.$route.name == 'Home' && !this.npsClosed && fnCheckConnection()) {
                    return 'nps'
                } else if (this.showCreateUsersPage() && this.$route.name == 'Home') {
                    return 'createUsers'
                }
                return false
            }
        },
        showErrorDeviceConfig() {
            if (this.$store.getters.getIsApp && this.$store.getters.getDateLimitation) {
                return !(this.$store.getters.getAutoTimeEnable && !this.$store.getters.getAirPlaneModeEnable)
            }
            return false
        },
        maintenance() {
            return this.$store.getters.getMaintenance
        },
        pullRefresh() {
            // PUT HERE CONDITIONS WHERE DO NOT DO PULL REFRESH
            if (this.$route.params && (this.$route.params.edit || ['Label', 'LabelResume', 'DigitalLabel'].includes(this.$route.name))) {
                return false
            } else return true
        },

        showingOverlay() {
            return this.$store.state['overlay_is_visible']
        },

        openedMenu() {
            return this.$store.state['menu_tools_is_expanded']
        },

        openedSubMenu() {
            return this.$store.state['submenu_tools_is_expanded']
        },

        update_app_min() {
            var version = this.$store.getters.getForceUpdate
            if (version) {
                this.checkPopupUpdate(version)
            }
            return version
        },
        update_available() {
            var version = this.$store.getters.getUpdateAvailable
            if (version) {
                this.checkPopupUpdate()
            }
            return version
        },
        showingPopup() {
            return this.$store.state['popup'].is_visible
        },
        showingTop() {
            return this.$store.state['menu_top_is_visible']
        },
        showingTools() {
            return this.$store.state['menu_tools_is_visible']
        },
        showingUser() {
            return this.$store.state['menu_user_is_visible']
        },
        showingAux() {
            return this.$store.state['menu_aux_is_visible']
        },
        userLogged() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        employeeLogged() {
            let employee_id = this.$store.getters['loginUser/getLocalEmployee']
            let employee = employee_id ? this.$store.getters['loginUser/getUserInformation'] : false

            if (employee_id) {
                this.loaded = true
                if (employee.is_admin) this.userHasValidConditionsVersion = true
                this.npsClosed = false
            }
            return this.userLogged ? this.$store.getters['employee/getEmployee'](this.userLogged) : false
        },
        homeURL() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        },
        isLoaded() {
            return this.$store.getters['login/isLoad']
        },
        getToken() {
            return this.$store.getters['login/getToken']
        },
        news() {
            return this.$store.getters['login/getReleases']()
        },
        geolocation() {
            return this.$store.getters.getGeoLocation
        },
        pusherMessage() {
            return this.$store.getters.getPusherData
        },
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        },
        showTrialEndScreen() {
            return this.$store.getters['login/getShowTrialEndScreen']
        }
    },

    methods: {
        hidePopupUpdate() {
            this.showUpdateScreen = false
            localStorage.setItem('askUpdateScreen', moment())
        },
        checkPopupUpdate(min) {
            if (localStorage.askUpdateScreen && moment().diff(moment(localStorage.askUpdateScreen), 'days') < 7 && !min) {
                this.showUpdateScreen = false
            }
        },
        closeNPS() {
            var self = this
            self.$store.dispatch('employee/loadEmployees', { force: true }).then(function() {
                self.$store.dispatch('employee/loadRoles', {}).then(function() {
                    self.npsClosed = true
                })
            })
        },

        /**
         * @param skip = string | undefined (if api fails, skip will be string equal to equal, for hide the form)
         */
        hasUsersLevelThree(skip) {
            if (skip == 'skip') {
                this.hasLevel3Users = true
                return true
            }

            var AllUsers = this.$store.getters['employee/getAllEmployees']
            var allRoles = this.$store.getters['employee/getAllRoles']
            if (Object.values(AllUsers).length > 0 && Object.values(allRoles).length > 0) {
                for (var user in AllUsers) {
                    var idx = AllUsers[user]
                    if (allRoles[idx.rol].level == 3) {
                        this.hasLevel3Users = true
                        return true
                    }
                }
                this.hasLevel3Users = false
                return false
            } else {
                this.hasLevel3Users = false
                return false
            }
        },
        popupPusher() {
            if (this.pusherMessage.message && this.userLogged) {
                this.$popup.alert({ message: this.pusherMessage.message })
                this.$store.commit('setPusherData', false)
            }
        },
        popupGeolocation() {
            var self = this
            if (
                navigator.permissions.query({ name: 'geolocation' }).then(function(permissionStatus) {
                    navigator.geolocation.getCurrentPosition(
                        function(position) {
                            log('GETTING POSITION: ', position)
                            var coords = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                            self.$store.commit('setGeoLocation', coords)
                        },
                        function(error) {
                            log('ERROR GEOLOCATION -> ', error)
                            if (error.code == 3) {
                                setTimeout(self.popupGeolocation, 10 * 60 * 1000)
                            }
                        },
                        { timeout: 3000 }
                    )
                }) == undefined
            ) {
            }
            navigator.permissions.query({ name: 'geolocation' }).then(function(permissionStatus) {
                // console.log('geolocation permission state is ', permissionStatus.state)
                log('GEO', permissionStatus)
                setTimeout(function() {
                    var popupPrinter = document.querySelector('body > div.popup-container.popup-label-admin')

                    if (permissionStatus.state == 'prompt' && popupPrinter == null) {
                        self.$popup.geolocation({
                            mood: 'geolocation'
                        })
                    }
                }, 600)
            })
        },
        // Pull 2 Refresh hijo del diablo
        handleHold(evt) {
            // console.log("%c -> HOLD "+evt.type, 'color: #5400a7');
            // console.log(" -> H."+this.pull.holding+" P."+this.pull.pulling);

            var self = this
            var eData = evt.type == 'touchstart' ? evt.changedTouches[0] : evt

            // Screen position
            let available = (40 * screen.height) / 100 // 40% del top del screen

            if (eData.clientY >= 0 && eData.clientY <= available) {
                self.pull.getLast = true
                self.pull.yStart = eData.clientY

                self.pull.triggerHold = setTimeout(function() {
                    self.handleStart(evt)
                }, self.pull.tHold)
            }
        },
        handleStart(evt) {
            // console.log("%c -> START "+evt.type, 'color: #8b8a00');
            // console.log(" -> H."+this.pull.holding+" P."+this.pull.pulling);
            var self = this

            if (!self.pull.pulling && !self.pull.refresh) {
                var eData = evt.type == 'touchstart' ? evt.changedTouches[0] : evt
                var target = eData.target

                let hasClasses = self.pull.pClass ? target.classList.value.indexOf(self.pull.pClass) != -1 : true
                let distance = self.pull.yLast ? self.pull.yLast - self.pull.yStart : 0

                if (hasClasses && distance > self.pull.minPull) {
                    log(' -> PULLING')
                    self.pull.pulling = true
                    self.pull.getLast = false
                }
            }
        },
        handleMove(evt) {
            var self = this
            var eData = evt.type == 'touchmove' ? evt.changedTouches[0] : evt

            if (self.pull.getLast) {
                self.pull.yLast = eData.clientY
            }
            if (self.pull.pulling) {
                // console.log("%c -> ... "+evt.type, 'color: #00392c');

                let distance = eData.clientY > self.pull.yStart ? eData.clientY - self.pull.yStart : 0
                let translate = distance > self.pull.maxDistance ? self.pull.maxDistance : distance

                let parent = document.getElementById('fullcontent')
                parent.style.overflowY = 'hidden'

                let elem = document.getElementById('content')
                if (elem) {
                    elem.style.transform = 'translateY(' + translate + 'px)'
                    // elem.style.transitionDuration = '0s'

                    // let topDetail = document.getElementsByClassName('top-info');
                    // if (topDetail) {
                    //   elem.style.top = translate+'px';
                    // }
                }
            }
        },
        handleEnd(evt) {
            // console.log("%c -> END "+evt.type, 'color: #aa0042');
            // console.log(" -> H."+this.pull.holding+" P."+this.pull.pulling);
            // console.log("");
            var self = this

            if (self.pull.triggerHold) clearTimeout(self.pull.triggerHold)
            var eData = evt.type == 'touchend' ? evt.changedTouches[0] : evt

            if (self.pull.pulling) {
                self.pull.yEnd = eData.clientY
                let yDiff = self.pull.yEnd - self.pull.yStart

                if (yDiff > self.pull.minDiff) {
                    self.pull.refresh = true
                    self.loaded = false

                    let menuaux = document.getElementById('menu-aux')
                    menuaux ? (menuaux.style.display = 'none') : false

                    // CUSTOM ACTIONS ON PAGES
                    switch (self.$route.name) {
                        case 'Checklist':
                            self.$store.commit('tasks/setDate', false)
                            break

                        default:
                            break
                    }

                    // Styles REFRESH de content
                    let elem = document.getElementById('content')
                    if (elem) {
                        // elem.style.transitionDuration = '0.2s'
                        elem.style.transform = 'translateY(' + screen.height + 'px)'
                    }

                    setTimeout(function() {
                        self.pull.refresh = false
                        self.resetStyles()
                        self.loaded = true
                        menuaux ? menuaux.removeAttribute('style') : false
                    }, self.pull.tRefresh)
                } else {
                    self.resetStyles()
                }

                self.pull.yStart = false
                self.pull.yEnd = false
            }

            self.pull.pulling = false
            self.pull.holding = false
            self.pull.getLast = false
            self.pull.yLast = false
        },
        resetStyles() {
            let parent = document.getElementById('fullcontent')
            parent.style.overflowY = 'auto'

            let elem = document.getElementById('content')
            if (elem) {
                // elem.style.transitionDuration = '0.2s'
                elem.style.transform = 'none'

                // let topDetail = document.getElementsByClassName('top-info');
                // console.log(topDetail);
            }
        },

        alertOffline() {
            this.$snackbar.offline({ message: this.$t('assets.no_signal'), closeable: true, duration: 5000 })
        },

        hideOverlay() {
            this.$store.commit('setOverlayVisible', false)
        },

        async validateConditionsVersion() {
            let user = this.$store.getters['loginUser/getUserInformation']
            log(user)
            if (user.is_admin) this.userHasValidConditionsVersion = true
            else {
                let result = true

                // TODO: remove
                const visible = false

                // add flag for production
                if (visible) {
                    // check if device has connection to validate legal conditions
                    if (fnCheckConnection()) {
                        const employee_id = this.$store.getters['loginUser/getLocalEmployee']
                        const user = employee_id ? this.$store.getters['employee/getEmployee'](employee_id) : false

                        // check if user is logged to validate legal version
                        if (user) {
                            const conditionsVersion = await this.$store.getters['login/getConditionsVersion']
                            const employeeConditionsVersion = user && user.legal_version ? user.legal_version : '0'

                            // if conditions are not equal, return false
                            result = conditionsVersion == employeeConditionsVersion

                            // show popup
                            if (!result) {
                                this.$store.dispatch('loginUser/showPopupConditions', { employee_id: this.employeeLogged.id })
                            }
                        }
                    }
                }

                this.userHasValidConditionsVersion = result
            }
        },
        isPwa() {
            return ['fullscreen', 'standalone', 'minimal-ui'].some(displayMode => window.matchMedia('(display-mode: ' + displayMode + ')').matches)
        },

        async install() {
            // PWA PROMPT
            // DEMO: moment().diff(moment(localStorage.askpwa), 'seconds') >= 10)
            // moment().diff(moment(localStorage.askpwa), 'days') >= 1)
            if ((this.deferredPrompt && typeof localStorage.askpwa == 'undefined') || (localStorage.askpwa && moment().diff(moment(localStorage.askpwa), 'days') >= 1)) {
                this.deferredPrompt.prompt().then(res => {
                    if (res && res.outcome == 'dismissed') {
                        localStorage.setItem('askpwa', moment())
                    }
                })
            }
        },
        showNextDayPopupAskCreateNewUsers() {
            this.npsClosed = true
            localStorage.setItem('askCreateUsers', moment())
            this.key++
        },
        showCreateUsersPage() {
            var allEmployees = this.$store.getters['employee/getAllEmployees']
            var allRoles = this.$store.getters['employee/getAllRoles']
            if (this.$route.name != 'ConfigPage') {
                var employeeThreeOrLess = []
                for (var employee in allEmployees) {
                    var idx = allEmployees[employee]
                    if (allRoles[idx.rol].level <= 3) {
                        employeeThreeOrLess.push(idx)
                    }
                }
                if (Object.values(employeeThreeOrLess).length > 1) {
                    return false
                } else if (Object.values(employeeThreeOrLess).length == 1 && Object.values(employeeThreeOrLess)[0].id == this.employeeLogged.id) {
                    if (!localStorage.askCreateUsers) {
                        return true
                    } else {
                        if (localStorage.askCreateUsers && moment().diff(moment(localStorage.askCreateUsers), 'days') >= 1) {
                            return true
                        } else {
                            return false
                        }
                    }
                }
            } else {
                if (this.employeeLogged && typeof this.employeeLogged.show_nps != 'undefined' && this.employeeLogged.show_nps) {
                    this.npsClosed = true
                }
            }
            return false
        },
        goToCreateUser() {
            this.$router.push('/config/users')
            this.showNextDayPopupAskCreateNewUsers()
            this.npsClosed = true
        },

        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2)
                return parts
                    .pop()
                    .split(';')
                    .shift()
        },
        yourCallBackFunction() {
            this.$popup.alert({
                title: 'Your title',
                message: 'Your message',
                ok: 'OK'
            })
        },

        load() {
            log('load')
            this.loaded = false
            if (this.isLoaded) {
                this.$store.commit('setMediaQuery', this.$mq)

                // PWA
                window.addEventListener('beforeinstallprompt', e => {
                    e.preventDefault()
                    // Stash the event so it can be triggered later.
                    this.deferredPrompt = e
                })

                this.$bar.reset()

                let self = this

                var checkConnectionInterval = window.setInterval(function() {
                    log('VERIFICANDO CONEXION')
                    let conn = fnCheckConnection()

                    if (self.connState && !conn) {
                        self.alertOffline()
                    } else if (!self.connState && conn) {
                        self.$snackbar.close()
                    }

                    self.connState = conn
                    self.$store.commit('setConnState', conn)
                }, 10000)

                if (this.getToken) {
                    log(this.getToken)

                    this.$store.commit('setMoment')

                    let promises = []

                    self.$store.dispatch('employee/loadEmployees').then(function(response) {
                        self.$store.dispatch('employee/loadRoles').then(function(response2) {
                            const skip = !response || !response2 ? 'skip' : false
                            self.loaded = true
                            self.hasUsersLevelThree(skip)
                        })
                    })

                    const tools = self.$store.getters['login/getConfig'].tools

                    if (tools.includes(1)) {
                        promises.push(self.$store.dispatch('checklist/loadTemplates', {}))
                    }

                    if (tools.includes(2)) {
                        promises.push(self.$store.dispatch('issues/loadTemplates', {}))
                    }

                    if (tools.includes(4)) {
                        promises.push(self.$store.dispatch('register/loadTemplates', {}))
                    }

                    if (tools.includes(7)) {
                        promises.push(self.$store.dispatch('audit/loadTemplates', {}))
                    }

                    Promise.all(promises)

                    if (tools.includes(1) || tools.includes(2) || tools.includes(4) || tools.includes(7)) {
                        self.$store.dispatch('tasks/loadAll', {
                            cType: 1,
                            date: moment().unix()
                        })
                    }

                    if (tools.includes(3) || tools.includes(11)) {
                        self.$store.dispatch('label/loadLabels', {})
                    }

                    self.$store.dispatch('login/loadReleases', {})
                } else if (this.$store.getters['loginUser/getLocalEmployee']) {
                    self.loaded = true
                }

                if (!this.$store.getters.getIsApp) {
                    log('COOKIE -> ', document.cookie)
                    if (!self.getCookie('usernameAndy')) {
                        document.cookie = 'usernameAndy=' + uuid.v4()
                    }
                    self.$store.commit('setUuid', self.getCookie('usernameAndy'))
                } else {
                    self.$store.commit('setUuid', window.device.uuid)
                }
            }
        },

        showSplashScreen() {
            const self = this
            const showSplashScreen = this.$store.getters['getShowSplashScreen']

            if (showSplashScreen) {
                this.$splashscreen.show()
                function sleep(ms) {
                    return new Promise(resolve => setTimeout(resolve, ms))
                }
                sleep(2000).then(() => {
                    self.$store.commit('setShowSplashScreen', 0)
                    self.$splashscreen.hide()
                })
            }
        }
    },
    created() {
        this.showSplashScreen()
        this.load()

        log('created default layout => this.load')
    },
    mounted() {},
    updated() {
        var self = this
        // onboarding
        // PWA
        if (this.userLogged && this.deferredPrompt && window.location.href.includes(this.homeURL) && !this.isPwa()) {
            this.install()
        }

        if ((this.userLogged && window.location.href.includes(this.homeURL) && !localStorage.askGeolocation) || moment().diff(moment(localStorage.askGeolocation), 'days') >= 1) {
            if (process.env.VUE_APP_IS_APP == 'FALSE' && !this.openedMenu) {
                this.popupGeolocation()
            }
        }
        this.showCreateUsersPage()
    },
    watch: {
        maintenance(val) {
            if (val) {
                var self = this
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.maintenance_mode_alert'),
                    textSave: self.$t('popup.text_ok'),
                    callSave: function(response) {
                        self.$popup.close()
                    }
                })
            }
        },
        employeeLogged: function() {
            this.validateConditionsVersion()
        },
        isLoaded: function() {
            if (this.isLoaded) {
                this.load()
            }
        },
        getToken: function() {
            if (this.isLoaded) {
                this.load()
            }
        }
    }
}
</script>

<style lang="scss">
#alex-app {
    background-color: $default-bg-color;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    position: relative;

    &.offline {
        // background-color: red;
    }
}

#fullcontent:not(.inLogin) {
    @include border-radius($default-border-radius);
    @include default-box-shadow();
    background-color: $default-bg-color;
    top: $menu-top-height + $alex-app-padding;
    right: $alex-app-padding;
    width: calc(100% - #{$menu-tools-min});
    height: calc(100% - #{$menu-top-height} - (#{$alex-app-padding} * 2));
    z-index: $z-content;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    // transition-duration: 0.4s;

    > #content {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        position: relative;
        background-color: $default-bg-color;
        // transition-duration: 0.2s;
        transform: none;

        & > .content,
        & > .column {
            // height: calc(100% - #{$menu-aux-height});
            height: calc(100%);
            padding-bottom: $menu-aux-height;
            overflow-x: hidden;
            overflow-y: auto;
        }

        & > .content.left,
        & > .column.left {
            float: left;
        }
        & > .content.right,
        & > .column.right {
            float: right;
        }

        & > .column {
            width: $column-width;
            max-width: $column-max-width;
            background-color: #fff;
            position: relative;
            z-index: $z-column;

            &.left {
                // @include box-shadow($h: -5px, $v: 0, $b: 10px, $s: 0px, $c: rgba(68,68,68,0.3));
                // border-right: 1px solid #eee;
            }
            &.right {
                // @include box-shadow($h: 5px, $v: 0, $b: 10px, $s: 0px, $c: rgba(68,68,68,0.3));
                // border-left: 1px solid #eee;
            }
        }
        & > .content {
            background-color: $default-bg-color;
            width: $content-width;
            min-width: calc(100% - #{$column-max-width});

            &.full {
                width: calc(#{$content-width} + #{$column-width});
            }
        }

        & > .column nav {
            @include background($size: 14px, $position: center right 6px, $image: img('right_dark.svg'));
            @include display-flex();
            @include align-items(flex-end);
            @include justify-content();
            @include flex-direction(column);
            padding-right: 30px;
            height: 35px;
            font-family: $text-light;
            text-align: right;
            color: #444;
            background-color: #fcfcfc;
            // background-color: #fff;
            // border-bottom: 1px solid #eee;

            &.close {
                background-image: img('close_dark.svg');
                background-size: 18px;
            }
        }

        & > .column .calendar-container,
        & > .column .filters {
            display: block;
            float: left;
            height: 100%;
            max-height: 50%;
        }

        & > .column .calendar-container {
            width: 100%;
            // background-color: #ddd;
            overflow: hidden;
            min-height: 260px;
            padding: 10px;
            float: none;
        }

        & > .column .filters {
            width: 80%;
            height: auto;
            margin: 0 10%;
            padding-top: 5px;
            padding-bottom: 60px;
            position: relative;

            .title {
            }

            input,
            label,
            .employee-search {
                width: 100%;
            }

            .filter-actions {
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include flex-wrap($w: wrap);
                // position: absolute;
                // bottom: 10px;
                margin: 10px auto;
                width: 100%;

                .results {
                    color: $main-s50;
                }

                .clear-filter {
                    @include border-radius(3px);
                    display: block;
                    height: 40px;
                    border: 1px solid #dddddd;
                    background-color: #f2f2f2;
                    margin-top: 5px;
                    padding: 0 20px;
                }
            }
        }
    }

    .pull-loader {
        flex-direction: row;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        -mos-flex-direction: row;
        -o-flex-direction: row;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        height: 100%;
        width: 100%;
        background-color: transparent;
        background-color: red;

        .box {
            display: box;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
        }

        .plCircle,
        .plInner,
        .plCore {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            flex: none;
            -webkit-flex: none;
            -moz-flex: none;
            -ms-flex: none;
            -o-flex: none;
        }

        .plCircle {
            align-self: center;
            -webkit-align-self: center;
            -moz-align-self: center;
            -o-align-self: center;
            -ms-align-self: center;
            align-items: center;
            -ms-align-items: center;
            justify-content: center;
            -ms-justify-content: center;
            height: 100px;
            width: 100px;
            background-color: #222;
        }
        .plInner {
            align-self: center;
            -ms-align-self: center;
            justify-content: center;
            -ms-justify-content: center;
            height: 50%;
            width: 50%;
            background-color: $default-bg-color;
            -webkit-animation-name: pullLoaderAnimate;
            -webkit-animation-duration: 500ms;
            -webkit-animation-direction: alternate;
            -webkit-animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
        }
        .plCore {
            align-self: center;
            -ms-align-self: center;
            height: 25%;
            width: 25%;
            background-color: #222;
            -webkit-animation-name: pullLoaderCoreAnimate;
            -webkit-animation-duration: 1s;
            -webkit-animation-direction: alternate;
            -webkit-animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
        }

        @include keyframes(pullLoaderAnimate) {
            to {
                height: 90%;
                width: 90%;
            }
        }
        @include keyframes(pullLoaderCoreAnimate) {
            to {
                height: 90%;
                width: 90%;
            }
        }
    }

    &.pulling {
        // background-color: red;

        > #content {
            opacity: 0.5;
        }
    }
    &.refresh {
        @include background($image: img('alexhead_04.svg'), $size: 5px, $color: $default-bg-color);
        animation-name: fullrefresh;
        animation-duration: 1.5s;

        > #content {
            opacity: 0.3;
            // transition-duration: 0.2s;
            // transform: translateY(100%);
        }
    }
    @include keyframes(fullrefresh) {
        0% {
            // background-color: $main;
            // background-size: 20px;
        }
        25% {
            background-color: $main;
            // background-size: 300px;
        }
        50% {
            background-size: 300px;
        }
        75% {
            background-color: $main;
            // background-size: 20px;
        }
        100% {
            background-color: $default-bg-color;
            background-size: 0px;
        }
    }
}

.opened-menu {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;

    #fullcontent {
        right: unset;
        left: $menu-tools-max;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#alex-app.portrait {
    #fullcontent {
        @include border-radius(0px);
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(#{$menu-top-height} - 3px);
        width: 100vw;
        height: calc(100% - #{$menu-top-height} + 3px);
        overflow: auto;
        overflow-y: auto;
        box-shadow: none;
        background-color: $default-bg-color;

        &.overlay {
            box-shadow: none;
            filter: brightness(0.4);
            z-index: 50;
        }

        #content {
            height: 100%;
            // padding: 15px;
            overflow: auto;
            max-height: none;
            background-color: $default-bg-color;

            .content {
                padding: 10px;
                background-color: $default-bg-color;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
</style>
