<template>
    <div id="update_app-container" :class="$mq">
        <div class="update-content">
            <div class="header-img">
                <img class="header-andy" src="~@/../public/img/alexhead_04.png" alt="andy-header" />
            </div>
            <div class="title-update">
                {{ $t('popup.error_device_network.title') }}
            </div>
            <div class="title-description-update" v-if="!min">
                {{ $t('popup.error_device_network.message') }}
            </div>
            <div class="title-description-update" v-else>
                {{ $t('popup.error_device_network.message_min') }}
            </div>
            <div class="footer-actions-update">
                <div class="test-now" @click="test()">
                    {{ $t('popup.error_device_network.test') }}
                </div>
                <div class="go-config" @click="goConfig()">
                    {{ $t('popup.error_device_network.go_config') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        min: {
            type: undefined,
            default: false
        }
    },
    data() {
        return {
            title: i18n.t('popup.update.title'),
            message: i18n.t('popup.update.message'),
            textDownload: i18n.t('popup.update.text_download')
        }
    },
    computed: {},
    methods: {
        goConfig() {
            if (this.$store.getters.getIsApp) {
                window.cordova.plugins.settings.open(
                    'date',
                    function() {
                        log('opened settings')
                    },
                    function() {
                        log('failed to open settings')
                    }
                )
            }
        },
        test() {
            var self = this
            this.$overlay.loading()

            if (this.$store.getters.getIsApp) {
                cordova.plugins.brotherprinter.getAutoTimeEnable(
                    function(enable) {
                        self.$store.commit('setAutoTimeEnable', enable)
                        self.$overlay.hide()
                    },
                    function(err) {
                        logError('ERROR AL OBTENER EL PARAMETRO' + err.toString())
                        self.$overlay.hide()
                    }
                )
                cordova.plugins.brotherprinter.getAirPlaneModeEnable(
                    function(enable) {
                        self.$store.commit('setAirPlaneModeEnable', enable)
                        self.$overlay.hide()
                    },
                    function(err) {
                        logError('ERROR AL OBTENER EL PARAMETRO' + err.toString())
                        self.$overlay.hide()
                    }
                )
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#update_app-container {
    @include display-flex();
    @include justify-content(center);
    width: 100%;
    height: 100%;

    .update-content {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);
        @include align-self(center);
        max-width: 600px;
        height: 100%;
        max-height: 700px;
        padding: 14px;

        .header-img {
            margin-top: 40px;
            .header-andy {
                max-width: 150px;
            }
        }

        .title-update {
            @include font-size(30px);
            color: $main-dark;
            font-family: $text-bold;
            text-align: center;
            line-height: 40px;
            margin-bottom: 10px;

            @include portrait-tablet {
                @include font-size(40px);
            }
        }
        .title-description-update {
            @include font-size(m);
            color: $inactive-s80;
            font-family: $text;
            text-align: center;
            margin-bottom: 10%;
            @include portrait-tablet {
                @include font-size(ml);
            }
        }
        .footer-actions-update {
            .test-now {
                @include font-size(m);
                @include border-radius(4px);
                background-color: $main-dark;
                color: #fff;
                font-family: $text-bold;
                padding: 25px 50px;
                @extend .interaction;
                text-align: center;

                @include portrait-tablet {
                    @include font-size(ml);
                }
            }

            .go-config {
                @include font-size(m);
                @include border-radius(4px);
                background-color: $neutro-dark;
                color: #000;
                font-family: $text-bold;
                margin-top: 5px;
                padding: 25px 50px;
                @extend .interaction;
                text-align: center;

                @include portrait-tablet {
                    @include font-size(ml);
                }
            }
        }
    }
    &.landscape {
        .update-content {
            .header-img {
                margin-top: 0px;
                .header-andy {
                    max-width: 200px;
                }
            }

            .title-update {
                @include font-size(40px);
            }
            .title-description-update {
                @include font-size(26px);
                line-height: 30px;
            }
            .footer-actions-update {
                .test-now {
                    @include font-size(22px);
                    padding: 30px 60px;
                }

                .update-later {
                    @include font-size(22px);
                }
            }
        }
    }

    &.desktop {
        .update-content {
            .header-img {
                margin-top: 0px;
                .header-andy {
                    max-width: 200px;
                }
            }

            .title-update {
                @include font-size(40px);
            }
            .title-description-update {
                @include font-size(26px);
                line-height: 30px;
            }
            .footer-actions-update {
                .test-now {
                    @include font-size(l);
                    padding: 30px 60px;
                }

                .update-later {
                    @include font-size(l);
                }
            }
        }
    }
}
</style>
